interface NwCookie {
  name: string
  option: {
    sameSite: 'lax'
    path: string
    maxAge: number
  }
}

export const useNwCookie = () => {
  const NwCookie: NwCookie = {
    name: 'nw',
    option: {
      sameSite: 'lax',
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
    },
  }
  const nwCookie = useCookie<boolean>(NwCookie.name, NwCookie.option)
  return {
    nwCookie,
  }
}
